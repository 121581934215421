import React from "react";

// Styles
import "./about.css";

// Images
import sliderImage1 from "assets/images/story/story-item.png";

// Icons
import DiscordIcon from "assets/icons/discord.png";
import TwitterIcon from "assets/icons/twitter.png";

export const About = () => {
  return (
    <section className="about-section" id="about">
      <div className="image-container">
        <div className="image">
          <img src={sliderImage1} alt="thumbnail" />
        </div>
      </div>
      <div className="content">
        <h2 className="about-heading">JOIN PVPWORLDS</h2>
        <p className="about-text">
          Join PvPWorlds in a PLAY AND EARN Minecraft world gain access through
          owning/renting your PvPWorld NFT to earn $WRLD from playing various
          minigames
        </p>
        <ol>
          <li>Download Minecraft Java</li>
          <li>Purchase or Rent a PvPWorld in order to play on the server</li>
          <li>?</li>
          <li>?</li>
        </ol>
        <div className="icon-buttons">
          <a
            href="https://discord.gg/9aq5M5NqUS"
            className="icon-btn"
            style={{ marginLeft: 0 }}
          >
            <img src={DiscordIcon} alt="dribble icon" /> <span>DISCORD</span>
          </a>
          <a href="https://twitter.com/PvPWorldsNFT" className="icon-btn">
            <img src={TwitterIcon} alt="twitter icon" /> <span>TWITTER</span>
          </a>
          <a
            href="https://medium.com/@pvpworlds.com/what-is-pvpworlds-a726a0686a1f"
            className="icon-btn"
          >
            WHITE PAPER
          </a>
        </div>
        <p className="foot-heading">
          There is no cost to join the server, other features however are
          available for free to the PvPWorlds community PvPWorlds is in no way
          affiliated with, endorsed by, or a partner of Minecraft, Mojang,
          Microsoft, or any other related parties
        </p>
      </div>
    </section>
  );
};
