export const members = [
  {
    id: 1,
    name: "ZEEMO",
    role: "Project lead",
    image: "https://i.imgur.com/qAIqBEs.png",
  },
  {
    id: 2,
    name: "TIM",
    role: "Render Artist",
    image: "https://i.imgur.com/p4DiGOJ.png",
  },
  {
    id: 3,
    name: "MICH",
    role: "Plugin dev",
    image: "https://i.imgur.com/oHSi267.png",
  },
  {
    id: 4,
    name: "BEDABLID",
    role: "Minecraft builder",
    image: "https://i.imgur.com/4AshAhg.png",
  },
  {
    id: 5,
    name: "AACHEL",
    role: "Server setup/administration",
    image: "https://i.imgur.com/sb8Xcvn.png",
  },
  {
    id: 6,
    name: "JOSH",
    role: "Blockchain Dev",
    image: "https://i.imgur.com/5Fbm8fr.png",
  },
  {
    id: 7,
    name: "ANTHONY",
    role: "Public Relations/Socials head",
    image: "https://i.imgur.com/uZUgnsd.png",
  },
];

export const showcaseItems = [
  {
    id: 1,
    image: "https://i.imgur.com/MrAThnb.png",
  },
  {
    id: 2,
    image: "https://i.imgur.com/3xttIkp.png",
  },
  {
    id: 3,
    image: "https://i.imgur.com/SiRkmkm.png",
  },
  {
    id: 4,
    image: "https://i.imgur.com/h2h9hAY.png",
  },
  {
    id: 5,
    image: "https://i.imgur.com/XBUCnDu.png",
  },
];
