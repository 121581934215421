import React from "react";

// Styles
import "./home.css";

// Video
import landingVideo from "assets/videos/Shorter.mp4";

export const Home = () => {
  return (
    <>
      <video autoPlay muted loop id="myVideo">
        <source src={landingVideo} type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>
      <section className="home-section" id="home">
        <div className="hero-section">
          <h1>PVP WORLDS IS COMING SOON</h1>
          <button>ENTER THE BATTLE</button>
        </div>
      </section>
    </>
  );
};
