import React from "react";

// Styles
import "./contact.css";

export const Contact = () => {
  return (
    <section className="contact-section" id="contact">
      <p className="contact-logo">
        PVP <span>Worlds</span>
      </p>
      <p className="contact-us">Contact Us</p>
      <p className="email">pvpworlds.com@gmail.com</p>
      <div className="footer-wrapper">
        <div className="divider"></div>
        <p className="copyright-text">Copyright © 2022 PvPworlds team</p>
      </div>
    </section>
  );
};
