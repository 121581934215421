import React, { useState } from "react";

// Styles
import "./team.css";

// Data
import { members, showcaseItems } from "data/team";

// Icons
import rightBtn from "assets/icons/right-btn.png";
import leftBtn from "assets/icons/left-btn.png";

export const Team = () => {
  const [activeMember, setActiveMember] = useState(1);
  const [activeShowcase, setActiveShowcase] = useState(1);

  const nextSlideShowcase = (index) => {
    if (index === showcaseItems?.length) {
      setActiveShowcase(1);
    } else {
      setActiveShowcase(index + 1);
    }
  };

  const prevSlideShowcase = (index) => {
    if (index - 1 === 0) {
      console.log("set to ", showcaseItems.length);
      setActiveShowcase(showcaseItems.length);
    } else {
      setActiveShowcase(index - 1);
    }
  };

  const nextSlide = (index) => {
    if (index === members?.length) {
      setActiveMember(1);
    } else {
      setActiveMember(index + 1);
    }
  };

  const prevSlide = (index) => {
    if (index - 1 === 0) {
      console.log("set to ", members.length);
      setActiveMember(members.length);
    } else {
      setActiveMember(index - 1);
    }
  };

  return (
    <section className="team-section" id="team">
      <div className="showcase">
        <h2>SHOW CASE</h2>
        <div className="images">
          {showcaseItems.map(({ id, image }) => (
            <div
              className={`showcase-box ${
                id !== activeShowcase ? "none" : "block"
              }`}
            >
              <img
                src={leftBtn}
                alt="prev"
                className="left"
                onClick={() => prevSlideShowcase(id)}
              />
              <img key={id} src={image} alt="show case" className="showcase-img" />
              <img
                src={rightBtn}
                alt="next"
                className="right"
                onClick={() => nextSlideShowcase(id)}
              />
            </div>
          ))}
        </div>
        <div className="indicators">
          {showcaseItems.map(({ id }) => (
            <div
              className={`${id !== activeShowcase ? "" : "active-indicator"}`}
            ></div>
          ))}
        </div>
      </div>
      <div className="team-members">
        <h2>OUR TEAM</h2>
        <div className="members-list">
          {members.map(({ id, name, role, image }) => (
            <div
              className={`member-box ${id !== activeMember ? "none" : "block"}`}
              key={id}
            >
              <div className="member-image-container">
                <img src={image} alt="avatar" />
              </div>
              <div className="content-and-btn">
                <img
                  src={leftBtn}
                  alt="prev"
                  className="left"
                  onClick={() => prevSlide(id)}
                />

                <div className="member-content">
                  <p className="member-name">{name}</p>{" "}
                  <p className="member-role">{role}</p>
                </div>
                <img
                  src={rightBtn}
                  alt="next"
                  className="right"
                  onClick={() => nextSlide(id)}
                />
              </div>
            </div>
          ))}
        </div>
        <div className="indicators">
          {members.map(({ id }) => (
            <div
              className={`${id !== activeMember ? "" : "active-indicator"}`}
            ></div>
          ))}
        </div>
      </div>
    </section>
  );
};
