import React from "react";

// Styles
import "./game.css";

export const Game = () => {
  return (
    <section className="game-section" id="game">
      <p>
        PvPWorlds is a PLAY and EARN minigame hub that seeks to rival servers
        such as Hypixel and Mineplex in the Minecraft community while rewarding
        the efforts of players to improve their skills in PvP games. PvPWorlds
        will utilize NFTWorlds as a launchpad in order to eventually develop our
        own token , in the beginning however we will utilize the NFTWorlds
        ecosystem and the $WRLD token in order to get everything in order.
      </p>
    </section>
  );
};
