import React, { useEffect, useState } from "react";

// Styles
import "./navbar.css";

// Icons
import MenuIcon from "assets/icons/menu.svg";
import CrossIcon from "assets/icons/cross.svg";
import DiscordIcon from "assets/icons/discord.png";
import TwitterIcon from "assets/icons/twitter.png";

export const Navbar = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [navBackground, setNavBackground] = useState("black");
  const [navHeight, setNavHeight] = useState("110px");

  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setNavBackground("black");
      setNavHeight("100px");
    } else {
      setNavBackground("transparent");
      setNavHeight("110px");
    }
  };

  useEffect(() => {
    changeBackground();
    window.addEventListener("scroll", changeBackground);
  });

  return (
    <div className="nav-wrapper">
      <nav
        className="nav-container-desktop"
        style={{ background: navBackground, height: navHeight }}
      >
        <div className="nav-links">
          <a href="#home" className="logo">
            PVP <span>Worlds</span>
          </a>
          <div className="links">
            <a
              style={{
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
              href="#home"
            >
              Home
            </a>
            <a href="#game">Game</a>
            <a href="#story">Story</a>
            <a href="#roadmap">Roadmap</a>
            <a href="#team">Team</a>
            <a
              style={{
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
              }}
              href="https://medium.com/@pvpworlds.com/what-is-pvpworlds-a726a0686a1f"
            >
              White Paper
            </a>
          </div>
        </div>
        <div className="nav-buttons">
          <a href="https://discord.gg/9aq5M5NqUS" className="icon-btn">
            <img src={DiscordIcon} alt="dribble icon" />
          </a>
          <a href="https://twitter.com/PvPWorldsNFT" className="icon-btn">
            <img src={TwitterIcon} alt="twitter icon" />
          </a>
          <button className="mint-btn">Mint Now</button>
        </div>
      </nav>

      {/* Mobile */}
      <nav
        className="nav-container-mobile"
        style={{ background: navBackground, height: navHeight }}
      >
        <div className="nav-links">
          <a href="#home" className="logo">
            PVP <span>Worlds</span>
          </a>
        </div>
        <div className="nav-buttons">
          <a href="https://discord.gg/9aq5M5NqUS" className="icon-btn">
            <img src={DiscordIcon} alt="dribble icon" />
          </a>
          <a href="https://twitter.com/PvPWorldsNFT" className="icon-btn">
            <img src={TwitterIcon} alt="twitter icon" />
          </a>
          <button className="mint-btn">Mint Now</button>
          <div className="dropdown">
            <button
              className="dropbtn"
              onClick={() => setOpenDrawer(!openDrawer)}
            >
              <div className="menu">
                <img src={openDrawer ? CrossIcon : MenuIcon} alt="menu icon" />
              </div>
            </button>
            <div className={`dropdown-content ${openDrawer ? "block" : null}`}>
              <a
                style={{
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                }}
                href="#home"
              >
                Home
              </a>
              <a href="#game">Game</a>
              <a href="#story">Story</a>
              <a href="#roadmap">Roadmap</a>
              <a href="#team">Team</a>
              <a
                style={{
                  borderBottomLeftRadius: "10px",
                  borderBottomRightRadius: "10px",
                }}
                href="https://medium.com/@pvpworlds.com/what-is-pvpworlds-a726a0686a1f"
              >
                White Paper
              </a>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};
