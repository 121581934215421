import React from "react";

// Styles
import "./story.css";

// Images
import sliderImage1 from "assets/images/story/story-item.png";

export const Story = () => {
  return (
    <section className="story-section" id="story">
      <div className="slider">
        <div className="slider-image">
          <img src={sliderImage1} alt="slider" />
        </div>
        <div className="slider-buttons">
          <div></div>
          <div className="active"></div>
          <div></div>
        </div>
      </div>
      <div className="content">
        <h2>10,000 PvPWorlds for you to conquer</h2>
        <p>
          Each PvPWorld gives you access to PvPWorlds metaverse, complete
          various challenges every day to gain bonus $WRLD tokens, the more
          PvPWorlds Nfts detected in your wallet the more challenges you get.
        </p>
      </div>
    </section>
  );
};
