import React from "react";

// Styles
import "./roadmap.css";

// Images
import BarImage from "assets/images/roadmap/bar.png";

export const Roadmap = () => {
  return (
    <section className="roadmap-section" id="roadmap">
      <h2>ROADMAP</h2>
      <div className="roadmap-details">
        <div className="left-col">
          <div className="desktop-card roadmap-card">
            <ul>
              <li>Renting/staking development</li>
              <li>cosmetic/kits integration</li>
              <li>Development of our own token</li>
            </ul>
          </div>
        </div>
        <div className="middle-col">
          <div className="roadmap-progress">
            <img src={BarImage} alt="bar" />
            <div className="first">10%</div>
            <div className="second">50%</div>
            <div className="last">100%</div>
          </div>
        </div>
        <div className="right-col">
          <div className="roadmap-card first-card">
            <ul>
              <li>Server launch</li>
              <li>Initial mint</li>
              <li>WRLD integration</li>
            </ul>
          </div>
          <div className="mobile-card roadmap-card second-card">
            <ul>
              <li>Renting/staking development</li>
              <li>cosmetic/kits integration</li>
              <li>Development of our own token</li>
            </ul>
          </div>
          <div className="roadmap-card last-card">
            <ul>
              <li>more game modes</li>
              <li>? </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};
