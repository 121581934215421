// Components
import {
  About,
  Contact,
  Game,
  Home,
  Navbar,
  Roadmap,
  Story,
  Team,
} from "components";

function App() {
  return (
    <div className="main-container">
      <Navbar />
      <Home />
      <Game />
      <Story />
      <Roadmap />
      <Team />
      <About />
      <Contact />
    </div>
  );
}

export default App;
